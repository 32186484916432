import { signal } from "@preact/signals-react";
import { showToast } from "./ui/components/Toasts.tsx";
import { api, apiUrl } from "./utils/api.ts";

interface Job {
    name: string;
    status: "queued" | "processing" | "uploading" | "completed";
    progress: number;
    upload: Record<string,number>;
}

export const runningJobs = signal<Map<string,Job>>(new Map());
let isConnected = false;
let lastUpdated = 0;
let fetchTimeout: NodeJS.Timeout;

const updateFrom = (jobs: Job[]) => {
    runningJobs.value = new Map(jobs.filter((j: any) => j).map((j) => [j.name, j]));
    lastUpdated = Date.now();
}
let isFetching = false;

async function fetchJobs() {
    if (isFetching) return;
    isFetching = true;
    try {
        let jobs = await api('GET', '/progress')
        updateFrom(jobs);
    } catch(e) {
        console.error("Failed to fetch jobs", e);
    }
    isFetching = false;
}

export const jobComplete = new EventTarget();

function connectWs() {
    let ws = new WebSocket(apiUrl.replace("http://", "ws://").replace("https://","wss://") + "/ws/progress");
    ws.onopen = () => {
        isConnected = true;
    }
    ws.onmessage = (ev) => {
        let msg = JSON.parse(ev.data);
        let {type,data} = msg;
        console.log('socket data', {type,data,msg});
        if (type === "update") {
            let nJobs = new Map(runningJobs.value);
            nJobs.set(data.name, data);
            runningJobs.value = nJobs;
        } else if (type === "init") {
            updateFrom(data);
        } else if (type === "completed") {
            showToast({
                title: data,
                text: "Processing complete",
                variant: "success"
            });
            jobComplete.dispatchEvent(new CustomEvent('complete', {detail: data}));
        }
    }
    ws.onclose = () => {
        console.log('socket disconnect');
        isConnected = false;
        setTimeout(connectWs, 1000);
    }
}

let hasStarted = false;

export async function getJobs() {
    if (hasStarted) return;
    setInterval(() => {
        let diff = Date.now() - lastUpdated;
        let anyRunning = [...runningJobs.value.values()].find(j => j.status !== "completed");
        if (isConnected ) {
            if (diff > 30_000) fetchJobs();
        } else if (anyRunning) {
            if (diff > 1_000) fetchJobs();
        } else {
            if (diff > 5_000) fetchJobs();
        }
    }, 100)
    
    
    connectWs();
    fetchJobs();
    hasStarted = true;
}