import { computed, signal, Signal } from "@preact/signals-react";
import { ChangeEvent, Fragment, useMemo, useState } from "react";
import { Button, CloseButton, Col, Form, Modal, Row } from "react-bootstrap";
import { api, toQueryString } from "../../utils/api.ts";
import { cleanFileName } from "../../utils/util.ts";
import { showToast } from "../components/Toasts.tsx";


export const UploadDISA: React.FC<{ show: boolean, hide: () => void }> = ({ show, hide }) => {
    const [ busy, setBusy ] = useState(false);
    const [ files, setFiles ] = useState<{file: File, cname: Signal<string> }[]>([]);


    const handleFileChange = (e: ChangeEvent<HTMLInputElement>) => {
        if (e.target.files) {
            let newFiles = Array.from(e.target.files!);
            setFiles((oldFiles) => [
                ...oldFiles.filter(file => !newFiles.find(f => f.name === file.file.name)),
                ...newFiles.map(file => ({
                    file,
                    cname: signal(file.name.replace('.docx', '').split('_').pop() || ""),
                }))
            ])
        }
    };

    function removeFile(fileName: string) {
        setFiles(files => files.filter(f => f.file.name !== fileName));
    }

    const handleUpload = async (force: boolean = false) => {
        
        if (files.length === 0)
            return showToast({
                variant: "warning",
                title: "Upload DISA",
                text: "Please select a file to upload."
            });

        let nameSet = new Set<string>();
        for (let { cname } of files) {
            if (nameSet.has(cname.value) || cname.value.trim() === "")
                return showToast({
                    variant: "warning",
                    title: "Upload DISA",
                    text: "Please ensure each file has a unique customer name."
                });
            nameSet.add(cname.value);
           
        }


        
        setBusy(true);
        for (var {file, cname} of [...files]) {
            try {
                const formData = new FormData();
                formData.append('files', file, cleanFileName(file));
            
                const data = await api('POST', '/upload/?' + toQueryString({
                    force, type: 'DISA', customer_name: cname.value
                }), formData);
    
                showToast({
                    variant: "success",
                    title: "Upload " + file.name,
                    text: data.message
                })
                removeFile(file.name);
            } catch (error) {
                showToast({
                    variant: "danger",
                    title: "Failed to upload file",
                    text: (error as any)?.message
                });
                console.error("Failed to upload files:", error);
            }
        };
        setBusy(false);
    };
    let filesBody = useMemo(() => files.map(({ file, cname }) => <Fragment key={file.name}>{computed(() => 
        <Form.Group controlId="file">
            <Form.Label><CloseButton onClick={() => removeFile(file.name)} /> {file.name}</Form.Label>
            <Row>
                <Col>
                    <Form.Control
                        type="text"
                        value={cname.value}
                        onChange={(e) => cname.value = e.target.value}
                        placeholder="Enter customer name"
                        disabled={busy}
                    />
                </Col>
            </Row>
        </Form.Group>
    )}</Fragment>),[files, busy]);


    return <Modal show={show} onHide={hide}>
        <Modal.Header closeButton>
            <Modal.Title>Upload DISA</Modal.Title>
        </Modal.Header>
        <Modal.Body>
            {filesBody}
            <Form.Group controlId="formFile">
                <Form.Label>Add Files:</Form.Label>
                <Form.Control type="file" onChange={handleFileChange} multiple disabled={busy} />
            </Form.Group>
        </Modal.Body>
        <Modal.Footer>
            <Button variant="outline-secondary" onClick={hide} disabled={busy}>
                Cancel
            </Button>
            <Button variant="primary" onClick={() => handleUpload()} disabled={busy} >
                {busy ? "Uploading" : "Upload"}
            </Button>
        </Modal.Footer>
    </Modal>
}